<template>
  <div
    v-if="!isActivity || (item.country && (item.country.toLowerCase() !== 'spain' && item.country.toLowerCase() !== 'españa' && !virtualActivity))"
    class="card"
  >
    <div
      v-b-toggle="`collapseTrips`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Does this activity imply travel outside Spain?</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseTrips`"
      visible
    >
      <div class="card-content"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body">
          <!-- <div
            v-if="!item.id || (item.trips && item.trips.length === 0)"
            class="form-check form-check-success form-switch d-flex align-items-center mb-2"
          >
            <input
              :checked="item.trips && item.trips.length > 0"
              type="checkbox"
              class="form-check-input"
              @click="hideTrips($event.target)"
            >
            <label
              class="form-check-label"
              for=""
            >
              <small class="ms-1">Yes </small>
            </label>
          </div> -->

          <div v-if="item.trips && item.trips.length > 0">
            <div
              v-for="trip, index in item.trips"
              :key="trip.id"
            >
              <div
                v-b-toggle="`collapseTrip-${index}`"
                class="row mt-2"
              >
                <div class="col">
                  <h4>
                    Trip {{ index + 1 }}
                    <small>
                      <a
                        v-if="!trip.isConfirmed || loggedUser.roles.includes('super-admin')"
                        class="text-danger float-end"
                        @click="deleteTrip(index)"
                      >
                        <i data-feather="trash-2" /> Remove
                      </a>
                    </small>
                  </h4>
                </div>
              </div>
              <b-collapse
                :id="`collapseTrip-${index}`"
                :visible="index == 0"
              >
                <div class="row">
                  <div class="col-sm-4">
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['country'] }}</label>
                      <input
                        v-model="trip.country"
                        class="form-control"
                        type="text"
                        :disabled="trip.isConfirmed && !loggedUser.roles.includes('super-admin')"
                      >
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div
                      class="mb-1 form-group required"
                    >
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['postal_code'] }}</label>
                      <input
                        v-model="trip.postal_code"
                        type="text"
                        class="form-control"
                      >
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['begin_date'] }}</label>
                      <date-picker
                        v-model="trip.start_date"
                        format="D MMM Y"
                        value-type="format"
                        class="w-100"
                        :disabled="trip.isConfirmed && !loggedUser.roles.includes('super-admin')"
                        @change="getRelatedTrips(index)"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['end_date'] }}</label>
                      <date-picker
                        v-model="trip.end_date"
                        format="D MMM Y"
                        value-type="format"
                        class="w-100"
                        :disabled="trip.isConfirmed && !loggedUser.roles.includes('super-admin')"
                        @change="getRelatedTrips(index)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="trip.alert"
                  class="div alert alert-warning"
                >
                  <div class="alert-body">
                    <p>
                      <i data-feather="alert-triangle" />
                      {{ labels['longer_days'] }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="trip.relatedTrips && trip.relatedTrips.length > 0"
                  class="row"
                >
                  <div class="col-sm-12">
                    <div class="alert alert-info p-1">
                      <p><strong>{{ labels['error_trips'] }}</strong></p>
                      <p
                        v-for="tr in trip.relatedTrips"
                        :key="tr.id"
                      >
                        - <strong>{{ tr[0] }}</strong>, from {{ tr[1] }} to {{ tr[2] }} in {{ tr[3] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['complete_postal_address'] }}</label>
                      <textarea
                        v-model="trip.complete_address"
                        class="form-control"
                        :disabled="trip.isConfirmed && !loggedUser.roles.includes('super-admin')"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label
                      for=""
                      class="form-label"
                    >{{ labels['postal_address_google_maps'] }}</label>
                    <div class="input-group">
                      <vue-google-autocomplete
                        :id="`${index}`"
                        ref="autocompletegoogle"
                        v-model="trip.address"
                        classname="form-control"
                        types=""
                        placeholder="Search for a location"
                        :disabled="trip.isConfirmed && !loggedUser.roles.includes('super-admin')"
                        @placechanged="getAddressData"
                      />
                      <a
                        id="linkToWeb"
                        :href="`https://maps.google.com/?${trip.cid || ''}`"
                        class="btn btn-outline-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Go to link"
                        target="_blank"
                      ><i data-feather="external-link" /></a>
                    </div>
                  </div>
                </div>
                <div class="row"><!-- este row sólo es editable por el admin, el researcher lo tiene disabled, tanto el checkbox como el textarea -->
                  <div
                    class="col-sm-6"
                  >
                    <div
                      v-if="(loggedUser.roles && loggedUser.roles.includes('super-admin'))"
                      class="mb-1"
                    >
                      <div
                        :class="{'form-check': loggedUser.roles && loggedUser.roles.includes('super-admin')}"
                        class="form-check-success"
                      >
                        <input
                          v-if="loggedUser.roles && loggedUser.roles.includes('super-admin')"
                          v-model="trip.tss"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <label
                          class="form-check-label"
                          for="colorCheck3"
                        >{{ labels['tss_informed'] }}</label>
                      </div>
                    </div>
                    <div
                      class="mb-1"
                    >
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['tss_information'] }}</label>
                      <br>
                      <textarea
                        v-if="trip.tss && loggedUser.roles && loggedUser.roles.includes('super-admin')"
                        v-model="trip.tss_information"
                        class="form-control"
                      />
                      <span
                        v-else-if="trip.tss"
                        v-html="trip.tss_information"
                      />
                      <div
                        v-else
                        class="alert alert-secondary p-1"
                        style="background-color: pink !important;"
                      >
                        Pending
                      </div>
                      <!-- el texto por defecto se añade al hacer check en el checbox -->
                    </div>
                  </div>
                  <div
                    class="col-sm-6"
                  >
                    <div
                      v-if="loggedUser.roles && loggedUser.roles.includes('super-admin')"
                      class="mb-1"
                    >
                      <div
                        :class="{'form-check': loggedUser.roles && loggedUser.roles.includes('super-admin')}"
                        class="form-check-success"
                      >
                        <input
                          v-model="trip.travel_information"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <label
                          class="form-check-label"
                          for="colorCheck3"
                        >{{ labels['travel_information'] }}</label>
                      </div>
                    </div>
                    <div
                      v-if="trip.travel_information"
                      class="mb-1"
                    >

                      <label
                        for=""
                        class="form-label"
                      >{{ labels['insurance_information'] }}</label>
                      <br>
                      <textarea
                        v-if="loggedUser.roles && loggedUser.roles.includes('super-admin')"
                        v-model="trip.insurance_information"
                        class="form-control"
                      />
                      <div
                        v-else
                        class="alert alert-secondary p-1"
                        style="background-color: pink !important;"
                        v-html="trip.insurance_information"
                      />
                      <a :href="`https://portal.icrea.cat/intranet/researcher/insurances/`">{{ labels['got_to_insurance'] }}</a>
                      <!-- el texto por defecto se añade al hacer check en el checbox -->
                    </div>
                  </div>
                </div>
                <a
                  v-if="(trip.isConfirmed || loggedUser.roles.includes('super-admin')) && !trip.request_sended"
                  class="btn btn-sm btn-warning"
                  @click="addRequestComment(trip)"
                ><i data-feather="send" /> Request change</a>
                <p
                  v-if="trip.request_sended"
                  class="text-success"
                >
                  <i data-feather="check" /> Request sent
                </p>
              </b-collapse>
              <hr>
            </div>
          </div>
          <a
            class="btn btn-sm btn-dark"
            @click="addEmptyTrip"
          ><i data-feather="plus" /> Add trip</a>
        </div>
      </div>
    </b-collapse>
    <ItemInfoSheet :table-id="requestId">
      <template #info-sheet-title>
        Send request change
      </template>
      <template
        #info-sheet
      >
        <div class="offcanvas-body offcanvas-body--view">
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Comment</label>
            <quill-editor
              ref="quillComment"
              v-model="comment"
            />
          </div>
        </div>
        <div class="offcanvas-footer mt-auto">
          <button
            v-if="!loading"
            type="button"
            class="btn btn-primary mb-1 d-grid w-100"
            @click="sendRequest"
          >
            Send
          </button>
          <button
            v-if="loading"
            class="btn btn-primary mb-1 d-grid w-100"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Sending...</span>
          </button>
        </div>
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Vue from 'vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    BCollapse,
    DatePicker,
    ItemInfoSheet,
    VueGoogleAutocomplete,
  },
  props: {
    item: { type: Object, required: true },
    virtualActivity: { type: Boolean, required: false, default: false },
    isActivity: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      hasTrips: false,
      requestId: 1,
      comment: '',
      loading: false,
      selectedTrip: null,
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
    country() {
      return this.item.country
    },
  },
  watch: {
    country() {
      if (this.country && this.country.toLowerCase() !== 'Spain') {
        if (!(this.item.trips && this.item.trips[0])) {
          this.addEmptyTrip()
        }
      } else {
        this.item.trips = []
      }
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
  async mounted() {
    this.checkDays()
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.trips`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    addRequestComment(trip) {
      this.selectedTrip = trip
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.requestId)
    },
    async sendRequest() {
      if (this.$refs.quillComment.quill.getLength() - 1 > 0) {
        Vue.swal({
          title: 'This action will send an e-mail to the person in charge',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            this.loading = true
            await this.$store.dispatch('tssManagement/sendRequest', {
              id: this.selectedTrip.id,
              data: { comment: this.comment },
            }).then(resp => {
              if (resp.status == 200) {
                this.loading = false
                this.item.trips.find(e => e.id == this.selectedTrip.id).comment = this.comment
                this.item.trips.find(e => e.id == this.selectedTrip.id).request_sended = true
                this.item.trips.find(e => e.id == this.selectedTrip.id).request_date = new Date().toISOString().substr(0, 10)
                this.item.trips.find(e => e.id == this.selectedTrip.id).request_status_id = 53
                this.comment = ''
                this.selectedTrip = null
                Vue.swal('Request sent', '', 'success')
                this.$store.dispatch('modals/toggleInfoItemSheet', false)
              } else {
                this.loading = false
                Vue.swal('Error sendind request', `${resp.message ? resp.message : ''}`, 'error')
              }
            })
          }
        })
      } else {
        Vue.swal('There is not meesage to send', '', 'warning')
      }
    },
    deleteTrip(index) {
      this.item.trips.splice(index, 1)
    },
    hideTrips(data) {
      if (!data.checked) {
        this.item.trips = []
      } else {
        this.addEmptyTrip()
      }
    },
    async addEmptyTrip() {
      this.item.trips.push({
        travel_information: false,
        insurance_information: 'For trips within Europe, remember to bring your European Health Card (TSE-Tarjeta Sanitaria Europea). For worldwide travel (including Europe) you can find the information about AIG travel insurance in the Insurance section. You could download the APP to take the Insurance on your cell phone.',
        tss_information: 'Registered trip',
      })
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    async getRelatedTrips(index) {
      if (this.isActivity) {
        const trip = this.item.trips[index]

        if (trip.start_date && trip.end_date && (trip.start_date < trip.end_date)) {
          this.checkDays(index)

          const { data } = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/get-related-activities`, {
            start_date: trip.start_date,
            end_date: trip.end_date,
            user_id: trip.user_id,
            id: trip.id,
          })

          if (data.length > 0) {
            this.item.trips[index].relatedTrips = data.map(tr => [
              tr.origenable.title,
              tr.start_date_formatted,
              tr.end_date_formatted,
              tr.country,
              tr.origenable.id,
            ])
          } else {
            this.item.trips[index].relatedTrips = null
          }
        } else {
          this.item.trips[index].relatedTrips = null
        }

        this.$forceUpdate()
      }
    },
    async checkDays(index) {
      // if (this.item.trips[index].start_date && this.item.trips[index].end_date) {
      const date1 = new Date(this.item.trips[index].start_date)
      const date2 = new Date(this.item.trips[index].end_date)
      const difference = date2.getTime() - date1.getTime()
      const days = Math.ceil(difference / (1000 * 3600 * 24))

      // this.item.trips[index].alert = days > 30 They WILL request this
      // this.$emit('changeStatus', days > 30)
      if (days > 30) {
        this.item.trips[index].alert = true
        const resp = await this.$store.dispatch('citizenships/filter', { name: this.item.trips[index].country, continent: 'Europe' })
        // this.item.trips[index].travel_information = resp.length > 0
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 100)
        this.$emit('changeStatus', true)
      } else {
        this.item.trips[index].alert = false
        const resp = await this.$store.dispatch('citizenships/filter', { name: this.item.trips[index].country, continent: 'Europe' })
        // this.item.trips[index].travel_information = resp.length > 0

        this.$emit('changeStatus', false)
      }
      // }
    },
    getFullAddress() {
      return ''
    },
    async getAddressData(data, dataPlace, id) {
      this.item.trips[id].complete_address = dataPlace.formatted_address
      this.item.trips[id].address = dataPlace.formatted_address
      this.item.trips[id].lat = data.latitude
      this.item.trips[id].lng = data.longitude
      this.item.trips[id].postal_code = data.postal_code
      const cid = dataPlace.url.split('?')[1]
      this.item.trips[id].cid = cid

      const countryCode = dataPlace.address_components.find(item => item.long_name === data.country).short_name
      const resp = await this.$store.dispatch('citizenships/filter', { iso2: countryCode })
      if (resp.length > 0) {
        this.item.trips[id].country = resp[0].name
      }

      // const resp2 = await this.$store.dispatch('citizenships/filter', { iso2: countryCode, continent: 'Europe' })
      // this.item.trips[id].travel_information = resp2.length > 0

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
}
</script>
